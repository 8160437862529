.ant-collapse-borderless{ background:#f6f9fb!important; }
.ant-collapse-header-text{ font-size:18px; }

.slick-track{ display:flex!important; align-items:center; }

.custom-confirm-styles > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  column-gap: 8px;
}